import React from 'react';
import './App.css';
import NavbarP from './components/navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Nosotros from './components/nosotros/Nosotros';
import Contacto from './components/contacto/contacto';
import Productos from './components/productos/productos';
import Footer from './components/footer/footer'; // Importa el componente Footer

function App() {
  return (
    <div className="App">
        <NavbarP />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productos" element={<Productos />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
        <Footer /> {/* Añade el Footer aquí */}
    </div>
  );
}

export default App;
