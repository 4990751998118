/*
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Palta from '../images/PaltaLogo.png';
import './productos.css';

const productos = [
  {
    id: 1,
    title: "Palta Hass ",
    text: "Su sabor y textura se juzga de acuerdo a la cantidad de aceite que contienen, determinado por el porcentaje de materia seca que queda tras un particular proceso de prueba al que se somete la fruta.",
    imgSrc: Palta
  },
  {
    id: 2,
    title: "Palta Hass Variedades",
    text: "Su sabor y textura se juzga de acuerdo a la cantidad de aceite que contienen, determinado por el porcentaje de materia seca que queda tras un particular proceso de prueba al que se somete la fruta.",
    imgSrc: Palta
  },
];

function Productos() {
  const navigate = useNavigate();

  return (
    <div className="productos">
      <Container style={{ marginTop: '80px' }}>
        <Row className="justify-content-center">
          {productos.map(producto => (
            <Col key={producto.id} md={4} className="d-flex justify-content-center mb-4">
              <Card style={{ width: '18rem', textAlign: 'center' }}>
                <Card.Img variant="top" src={producto.imgSrc} />
                <Card.Body>
                  <Card.Title>{producto.title}</Card.Title>
                  <Card.Text>{producto.text}</Card.Text>
                  <Button variant="primary" onClick={() => navigate('/contacto')}>Contacto</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Productos;


*/
// components/productos/productos.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Palta from '../images/PaltaLogo.png';
import './productos.css';

const productos = [
  {
    id: 1,
    title: "Palta Hass ",
    text: "Su sabor y textura se juzga de acuerdo a la cantidad de aceite que contienen, determinado por el porcentaje de materia seca que queda tras un particular proceso de prueba al que se somete la fruta.",
    imgSrc: Palta
  },
  {
    id: 2,
    title: "Palta Hass Variedades",
    text: "Su sabor y textura se juzga de acuerdo a la cantidad de aceite que contienen, determinado por el porcentaje de materia seca que queda tras un particular proceso de prueba al que se somete la fruta.",
    imgSrc: Palta
  },
  {
    id: 3,
    title: "Palta Fuerte",
    text: "Una variedad de palta con una piel más delgada y un sabor más suave, ideal para ensaladas y guacamole.",
    imgSrc: Palta
  },
];

function Productos() {
  const navigate = useNavigate();

  return (
    <div className="productos">
      <Container fluid className="pt-5">
        <Row>
          <Col>
            <h2 className="text-center text-white mb-4">Nuestros Productos</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {productos.map(producto => (
            <Col key={producto.id} xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center mb-4">
              <Card className="product-card">
                <Card.Img variant="top" src={producto.imgSrc} />
                <Card.Body>
                  <Card.Title>{producto.title}</Card.Title>
                  <Card.Text>{producto.text}</Card.Text>
                  <Button variant="primary" onClick={() => navigate('/contacto')}>Contacto</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Productos;
