/*   
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './home.css';
import CustomCarousel from './carousel/carousel';  // Asegúrate de importar el carrusel

function Home() {
  return (
    <Container fluid className='home'>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col xs={12}>
          <h1 className="text-center">FRUTICOLA HACIENDA MACUL</h1>
        </Col>
      </Row>
      <Row className="justify-content-center mt-3 content">
        <Col xs={12} md={6}>
          <p className="text-left">
            Somos Frutícola Hacienda Macul: Venta de frutas y verduras al por mayor y menor, con entrega a domicilio en la Región Metropolitana. Ingredientes de la mejor calidad, ¡directo desde el campo a tu mesa!
          </p>
        </Col>
        <Col xs={12} md={6}>
          <div className="carousel-container">
            <CustomCarousel />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;


*/

/*

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './home.css';
import CustomCarousel from './carousel/carousel';  

function Home() {
  return (
    <Container fluid className='home'>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col xs={12}>
          <h1 className="text-center">FRUTICOLA HACIENDA MACUL</h1>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <div className="carousel-container">
            <CustomCarousel />
          </div>
        </Col>
        <Col xs={12}>
          <p className="text-center description">
            Somos Frutícola Hacienda Macul: Venta de frutas y verduras al por mayor y menor, con entrega a domicilio en la Región Metropolitana. Ingredientes de la mejor calidad, ¡directo desde el campo a tu mesa!
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;

*/


import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './home.css';
import CustomCarousel from './carousel/carousel';

function Home() {
  return (
    <Container fluid className='home'>
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col xs={12}>
          <h1 className="text-center">FRUTICOLA HACIENDA MACUL</h1>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-center">
          <div className="carousel-container">
            <CustomCarousel />
          </div>
        </Col>
        <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
          <p className="text-center description">
            Somos Frutícola Hacienda Macul: Venta de frutas y verduras al por mayor y menor, con entrega a domicilio en la Región Metropolitana. Ingredientes de la mejor calidad, ¡directo desde el campo a tu mesa!
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;




