import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import '../nosotros/nosotros.css';
import Nosotros from '../images/P1.jpg'; // Asegúrate de tener esta imagen en tu proyecto

function SobreNosotros() {
  return (
    <div className="sobre-nosotros">
      <Container>
        <Row className="align-items-center mt-5">
          <Col md={6}>
            <h2>Sobre Nosotros</h2>
            <p>
              Somos una empresa dedicada a comercialización de paltas de alta calidad. Nuestro compromiso es ofrecer productos frescos y saludables, cultivados con prácticas sostenibles y amigables con el medio ambiente.
            </p>
            <p>
              Contamos con un equipo apasionado y experimentado, enfocado en brindar la mejor experiencia a nuestros clientes, desde la selección de la fruta hasta la entrega en su hogar.
            </p>
            <p>
              Nuestra misión es ser líderes en el mercado de frutas frescas, proporcionando siempre productos de excelencia y fomentando una vida saludable.
            </p>
          </Col>
          <Col md={6}>
            <Image src={Nosotros} fluid rounded />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SobreNosotros;
