import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Logo from '../images/vegetales.png';
import './Navbar.css';

function NavbarP() {
  return (
    <Navbar className="NavbarP" expand="lg" >
      <Container className="cont">
        <Navbar.Brand href="/">
          <img src={Logo} width={55} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="responsive-navbar-nav" id="basic-navbar-nav">
          <Nav className="sub-nav" style={{fontSize:'22px', fontWeight:'bold', color:'black'}}>
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/nosotros">Sobre Nosotros</Nav.Link>
            <Nav.Link href="/productos">Productos</Nav.Link>
            <Nav.Link href="/contacto">Contacto</Nav.Link></Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}
export default NavbarP;


