// components/footer/Footer.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../footer/footer.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <Container>
        <Row>
          <Col xs={12} md={4} className="text-center text-md-left mb-3 mb-md-0">
            <h5>Fruticola Hacienda Macul</h5>
            <p>Venta de frutas y verduras de calidad.</p>
          </Col>
          <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
            <h5>Enlaces</h5>
            <ul className="list-unstyled">
              <li><a href="/" className="text-white">Inicio</a></li>
              <li><a href="/productos" className="text-white">Productos</a></li>
              <li><a href="/contacto" className="text-white">Contacto</a></li>
              <li><a href="/nosotros" className="text-white">Nosotros</a></li>
            </ul>
          </Col>
          <Col xs={12} md={4} className="text-center text-md-right">
            <h5>Contacto</h5>
            <p>Email: fruticolamacul@gmail.com</p>
            <p>Teléfono: +56944501302 </p>
          </Col>
        </Row>
        <Row className="pt-3 border-top">
          <Col className="text-center">
            <p className="mb-0">© 2024 Fruticola Hacienda Macul. Todos los derechos reservados.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;


