import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import P1 from '../images/P1.jpg';
import P2 from '../images/P2.jpg';
import P3 from '../images/P3.jpg';
import P4 from '../images/P4.jpg';
import './carousel.css';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const CustomCarousel = () => {
  return (
    <Slider {...settings}>
      <div className="carousel-item"><img src={P1} alt="Palta 1" /></div>
      <div className="carousel-item"><img src={P2} alt="Palta 2" /></div>
      <div className="carousel-item"><img src={P3} alt="Palta 3" /></div>
      <div className="carousel-item"><img src={P4} alt="Palta 4" /></div>
    </Slider>
  );
};

export default CustomCarousel;

