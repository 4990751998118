import '../contacto/contacto.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
    return (
        <section className="contact-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="card contact-card">
                            <div className="card-body">
                                <h2 className="card-title text-center">FRUTICOLA HACIENDA MACUL</h2>
                                <ul className="contact-info">
                                    <li><strong>Nombre:</strong> Eduardo Alejandro Hernandez Ramirez</li>
                                    <li><strong>Teléfono:</strong> +56944501302</li>
                                    <li><strong>Email:</strong> fruticolamacul@gmail.com</li>
                                </ul>
                            </div>
                            <div className="card-footer">
                                <a href="https://wa.me/56944501302" className="btn btn-whatsapp">
                                    <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-lg-8">
                        <div className="map-container">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.9051401862384!2d-70.61399296133116!3d-33.42571737918358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf644301632b%3A0x6144f35c641cc38a!2sAv.%20Nueva%20Providencia%201881%2C%207500520%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1719439461356!5m2!1ses!2scl"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;
